<template>
    <Toast />
    <div class="flex align-items-center mb-3">
        <div class="surface-card p-4 shadow-2 border-round w-full">
            <div>
                <Photo/>

                <label for="name" class="block text-900 font-medium mb-2">Full Name</label>
                <InputText id="name" type="text" class="w-full mb-3" v-model="name" />

                <label for="email" class="block text-900 font-medium mb-2">E-Mail</label>
                <InputText id="email" type="text" class="w-full mb-3" v-model="email" />

                <label for="country" class="block text-900 font-medium mb-2">Country of residence</label>
                <AutoComplete forceSelection placeholder="Country" id="country" :dropdown="true" :multiple="false" v-model="selectedAutoValue" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="name"/>
                <br>
                <br>

                <div  v-if="!iscoach">
                <label for="time_pref" class="block text-900 font-medium mb-2">Coaches time preference</label>
                <Dropdown id="time_pref" v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Select time preference" />
                <br>
                <br>
                </div>
                <div v-else>
                    <label for="pricerange" class="block text-900 font-medium mb-2"
                      >Your price range</label
                    >
                    <Dropdown
                      id="pricerange"
                      v-model="dropdownValuePR"
                      :options="dropdownValuesPR"
                      optionLabel="name"
                      placeholder="Select price range"
                    />
                    <br>
                    <br>
                    <label for="preferred_coaching" class="block text-900 font-medium mb-2"
                      >Type of preferred coaching</label
                    >
                    <Dropdown
                      id="preferred_coaching"
                      v-model="dropdownValuePC"
                      :options="dropdownValuesPC"
                      optionLabel="name"
                      placeholder="Select your type"
                    />
                    <br>
                    <br>
                </div>

                <div v-if="iscoach">
                <Textarea v-model="nutshell" :autoResize="true" rows="5" placeholder="In a nutshell - what can users expect from you? (<100 words)" />
                <br>
                <br>
                </div>
                <Textarea v-model="presentation" :autoResize="true" rows="5" placeholder="Write something about yourself." />
                <br>
                <br>
                <div v-if="!iscoach">
                <Textarea v-model="expectations" :autoResize="true" rows="5" placeholder="Write about your expectations in terms of coaching." />
                <br>
                <br>
                </div>
                <div v-else>
                <Textarea v-model="education" :autoResize="true" rows="5" placeholder="List your education." />
                <br>
                <br>
                <Textarea v-model="work_experience" :autoResize="true" rows="5" placeholder="List your work experience." />
                <br>
                <br>
                <Textarea v-model="coaching_experience" :autoResize="true" rows="5" placeholder="List your coaching experience." />
                <br>
                <br>
                </div>


                <!-- Socials -->
                <div v-if="iscoach">
                <span class="p-input-icon-left">
                    <i class="pi pi-youtube"></i>
                    <InputText v-model="ytlink" style="width:100%" type="text" placeholder="Link to your introductory video, e.g. 'https://www.youtube.com/watch?v=GZ1aTwwLS44'" />
                </span>
                <br>
                <div class="ytvideo" v-if="ytlink != undefined && ytlink != '' && ytlink.includes('youtube.com')">
                    <iframe width="560" height="340" :src="ytlink.replace('watch?v=', 'embed/')" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <br v-if="ytlink != ''">
                <span class="p-input-icon-left">
                    <i class="pi pi-linkedin"></i>
                    <InputText v-model="linkedinlink" style="width:100%" type="text" placeholder="Link to your LinkedIn profile" />
                </span>
                <br>
                <br>
                <span class="p-input-icon-left">
                    <i class="pi pi-twitter"></i>
                    <InputText v-model="twitterlink" style="width:100%" type="text" placeholder="Link to your Twitter account" />
                </span>
                <br>
                <br>
                <span class="p-input-icon-left">
                    <i class="pi pi-globe"></i>
                    <InputText v-model="websitelink" style="width:100%" type="text" placeholder="Link to your website" />
                </span>
                <br>
                <br>
                </div>



                <!--<label for="password" class="block text-900 font-medium mb-2">Password</label>
                <InputText id="password" type="password" class="w-full mb-3" v-model="password" />-->

                <Button label="Save" icon="pi pi-check" class="w-full" v-on:click="submit"></Button>
            </div>
        </div>


    </div>
</template>

<script>
import CountryService from '../service/CountryService';
import UserService from '../service/UserService';
import Photo from './C_Photo.vue';

export default {
    components: {Photo},
    data() {
        return {
            dropdownValues: [
                {name: 'Same timezone', code: 'same'},
                {name: '+/- 3 hours', code: '3h'},
                {name: 'Indifferent', code: 'indifferent'}
            ],
            dropdownValue: null,
            selectedAutoValue: null,
            autoFilteredValue: [],
            name: "",
            email: "",
            password: "",
            iscoach: null,
            presentation: "",
            expectations: "",
            education: "",
            work_experience: "",
            coaching_experience: "",
            nutshell: "",
            ytlink: "",
            linkedinlink: "",
            twitterlink: "",
            websitelink: "",
            dropdownValuesPR: [
                { name: "<50$" },
                { name: "50-80$"},
                { name: "80-120$" },
                { name: "120-200$" },
                { name: "200-300$" },
                { name: "300$+" },
            ],
            dropdownValuePR: { name: "<50$" },
            dropdownValuesPC: [
                { name: "Group" },
                { name: "Subscription (3 months)"},
                { name: "Subscription (6 months)" },
                { name: "Subscription (>6 months)" },
                { name: "Individual Sessions" },
                { name: "Other" },
            ],
            dropdownValuePC: { name: "Individual Sessions" },
        }
    },
    countryService: null,
    userService: null,
    created() {
        this.countryService = new CountryService();
        this.userService = new UserService();
    },
    mounted() {
        this.countryService.getCountries().then(data => this.autoValue = data);
        this.userService.getUser(-1).then(data => {
            this.name = data["name"];
            this.email = data["email"];
            for (var i in this.dropdownValues) {
                var val = this.dropdownValues[i];
                if (val["name"] == data["time_zone_pref"]) {
                    this.dropdownValue = val;
                }
            }
            // this.selectedAutoValue = this.searchDirect(data["country"]);
            this.searchDirect2(data["country"]);
            this.iscoach = data["coach"];
            this.presentation = data["about"];
            this.expectations = data["expectations"];
            this.education = data["education"];
            this.work_experience = data["work_experience"];
            this.coaching_experience = data["coaching_experience"];
            this.nutshell = data["nutshell"];
            this.ytlink = data["ytlink"];
            this.linkedinlink = data["linkedinlink"];
            this.twitterlink = data["twitterlink"];
            this.websitelink = data["websitelink"];
            this.dropdownValuePR = {"name": data["pricerange"]};
            this.dropdownValuePC = {"name": data["preferred_coaching"]};
        });
    },
    methods: {
        searchDirect2(query) {
            this.selectedAutoValue = this.autoValue.filter((country) => {
                return country.name.toLowerCase().startsWith(query.toLowerCase());
            })[0];
        },
        searchDirect(query) {
            setTimeout(() => {
                if (!query.trim().length) {
                    this.autoFilteredValue = [...this.autoValue];
                }
                else {
                    this.autoFilteredValue = this.autoValue.filter((country) => {
                        return country.name.toLowerCase().startsWith(query.toLowerCase());
                    });
                }
            }, 20);
        },
        searchCountry(event) {
            this.searchDirect(event.query);
        },
        submit(event) {
            var time_zone_pref = null;
            var country = null;
            var pricerange = null;
            var preferred_coaching = null;
            if (this.dropdownValue && "name" in this.dropdownValue) {
                time_zone_pref = this.dropdownValue["name"];
            }
            if (this.selectedAutoValue && "name" in this.selectedAutoValue) {
                country = this.selectedAutoValue["name"];
            }
            if (this.dropdownValuePR && "name" in this.dropdownValuePR) {
                pricerange = this.dropdownValuePR["name"];
            }
            if (this.dropdownValuePC && "name" in this.dropdownValuePC) {
                preferred_coaching = this.dropdownValuePC["name"];
            }
            this.userService.updateUser(
                {
                    "name": this.name,
                    "email": this.email,
                    "country": country,
                    "time_zone_pref": time_zone_pref,
                    "about": this.presentation,
                    "expectations": this.expectations,
                    "education": this.education,
                    "work_experience": this.work_experience,
                    "coaching_experience": this.coaching_experience,
                    "nutshell": this.nutshell,
                    "ytlink": this.ytlink,
                    "linkedin": this.linkedin,
                    "twitterlink": this.twitterlink,
                    "websitelink": this.websitelink,
                    "pricerange": pricerange,
                    "preferred_coaching": preferred_coaching,
                }
            ).then(() => {
               this.$toast.add({severity: 'info', summary: 'Success', detail: 'Updated profile.', life: 3000});
            });
        },
    }
}
</script>
<style scoped>
textarea {
    width: 100%;
}
.p-input-icon-left {
    width: 100%;
}
</style>
